import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";
import {useContext} from "react";
import ConfigContext from "../../context/ConfigContext";

const Impressum = () => {
    const {language} = useContext(ConfigContext);
    const text_hu = 'Impresszum: \n\nCégnév: Jet Travel Kft.\n\n Ügyvezető igazgató: Toldi Gabriella\n\n Szakmai tevékenységért felelős: Toldi Gabriella\n\n Székhely: 1113 Budapest, Bocskai út 77-79. B. épület 3. emelet\n\n Adószám: 10274837-2-43\n\n Cégjegyzékszám: 01-09-063771\n\n Telefon: +361 209 2110\n\n Fax: +361 209 2111\n\n E-mail: jettravel@jettravel.hu\n\n Számlavezető pénzintézet: Raiffeisen Bank Zrt.\n\n HUF IBAN: HU08 1200 1008 0147 4956 0010 0001\n\n EUR IBAN: HU08 1200 1008 0147 4956 0020 0008\n\n USD IBAN: HU84 1200 1008 0147 4956 0030 0005\n\n SWIFT: UBRTHUHB\n\n Nyilvántartási szám: R00334/1992/1999\n\n Vagyoni biztosíték biztosítása: Alfa Vienna Insurance Group Biztosító Zrt.\n\n Vállalati Felelősségbiztosító: Alfa Vienna Insurance Group Biztosító Zrt.\n\n Tárhely szolgáltató neve: EMAB ZRT\n\n Tárhely szolgáltató székhelye: 1113 Budapest, Bocskai út 77-79.\n\n Tárhely szolgáltató telefonszáma: +361 785 4043\n\n E-mail cím: informatika@emab.hu\n\n'
    const text_en = 'Impressum\n\n' +
        'Company name: Jet Travel Kft.\n\n' +
        'Managing director: Gabriella Toldi\n\n' +
        'Responsible for professional activities: Gabriella Toldi\n\n' +
        'Address: 1113 Budapest, Bocskai út 77-79. B. épület 3. emelet\n\n' +
        'VAT number: 10274837-2-43\n\n' +
        'EU VAT number: HU10274837\n\n' +
        'Company registration number: 01-09-063771\n\n' +
        'Phone: +361 209 2110\n\n' +
        'Fax: +361 209 2111\n\n' +
        'E-mail: jettravel@jettravel.hu DMC oldalon helyesen: info@jettraveldmc.com\n\n' +
        '\n\n' +
        'Account holding bank: Raiffeisen Bank Zrt.\n\n' +
        'HUF IBAN: HU08 1200 1008 0147 4956 0010 0001\n\n' +
        'EUR IBAN: HU08 1200 1008 0147 4956 0020 0008\n\n' +
        'USD IBAN: HU84 1200 1008 0147 4956 0030 0005\n\n' +
        'SWIFT: UBRTHUHB\n\n' +
        '\n\n' +
        'Register number: R00334/1992/1999\n\n' +
        'Asset Security Insurance: Alfa Vienna Insurance Group Biztosító Zrt.\n\n' +
        'Corporate Liability Insurer: Alfa Vienna Insurance Group Biztosító Zrt.\n\n' +
        '\n\n' +
        'Hosting provider: EMAB ZRT\n\n' +
        'Hosting provider’s address: 1113 Budapest, Bocskai út 77-79.\n\n' +
        'Hosting provider’s phone number: +361 785 4043\n\n' +
        'E-mail: informatika@emab.hu'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>Impresszum</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {language === 'hu' ? text_hu : text_en}
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default Impressum